import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import { lodash as _, DarkModeContext } from "@datwyler/mfe-shared-components";
import { TenantIdProvider } from "@datwyler/shared-components";
import Alarms from "@containers/alarms";
import { ThemeContextType } from "@types";
const App = () => {
  const { theme } = useContext<ThemeContextType>(DarkModeContext);
  const location = useLocation();

  const tenantId = _.get(location, "state.tenantId");

  return (
    <TenantIdProvider tenantId={tenantId}>
      <ConfigProvider theme={theme}>
        <Alarms tenantId={tenantId} />
      </ConfigProvider>
    </TenantIdProvider>
  );
};

export default App;
