import { useEffect, useContext, useMemo, useState } from "react";
import { Flex, Card } from "antd";
import {
  BasicButton,
  useIntl,
  DownloadIcon,
  SingleSelect,
  exportToExcel,
  NotificationContext,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import Table from "@components/Table";
import Breadcrumbs from "@components/Breadcrumb";
import AlarmFilter from "@components/AlarmFilter";
import UserSearchModal from "@components/UserSearchModal";
import useAlarms from "@hooks/useAlarm";
import { getExcelData } from "@helpers";
import { AlarmsContainerType, AlarmStatus } from "@types";

const { Title, Body16RegularTertiary } = commonStyles;

const Alarms = ({ tenantId }: AlarmsContainerType) => {
  const {
    alarms,
    updatedAlarms,
    locations,
    devices,
    users,
    totalRows,
    rowsPerPage,
    pageNumber,
    alarmsToExport,
    sortBy,
    escalationLogs,
    setPageNumber,
    setRowsPerPage,
    setSortBy,
    setFilters,
    assignAlarm,
    exportAlarmsFile,
    unassignAlarm,
    updateAlarm,
    getEscalationLogs,
  } = useAlarms(tenantId);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { openNotification } = useContext<any>(NotificationContext);
  const intl = useIntl();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState();

  useEffect(() => {
    if (alarmsToExport.length > 0) {
      const data = getExcelData(intl, alarmsToExport);
      exportToExcel(data, intl.formatMessage({ id: "alarm_excel_filename" }));
    }
  }, [alarmsToExport, exportAlarmsFile, intl]);

  useEffect(() => {
    const deactivatedAlarms = updatedAlarms.filter(
      ({ status }) => status === AlarmStatus.DEACTIVATED
    );
    if (deactivatedAlarms.length > 0) {
      openNotification({
        type: "warning",
        description: intl.formatMessage({
          id:
            deactivatedAlarms.length > 0
              ? "alarms_deactivated_msg"
              : "alarm_deactivated_msg",
        }),
        placement: "topRight",
      });
    } else if (updatedAlarms.length > 0) {
      openNotification({
        type: "success",
        description: intl.formatMessage({
          id: "alarm_status_update",
        }),
        placement: "topRight",
      });
    }
  }, [intl, openNotification, updatedAlarms]);

  const handleAssingTo = (user: string) => {
    assignAlarm(user, selectedAlarm);
    setOpenUserModal(false);
  };

  const sortOptions = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: "alarm_date" }),
        value: "time",
      },
      {
        label: intl.formatMessage({ id: "alarm_status" }),
        value: "status",
      },
      {
        label: intl.formatMessage({ id: "alarm_last_updated_time" }),
        value: "updatedOn",
      },
      {
        label: intl.formatMessage({ id: "alarm_level" }),
        value: "severity",
      },
    ];
  }, [intl]);

  const handleExport = () => {
    exportAlarmsFile();
  };

  return (
    <>
      <Flex vertical gap={28}>
        <Breadcrumbs items={[{ title: "alarms" }]} />
        <Card>
          <Flex vertical gap={24}>
            <Flex align="center" justify="space-between">
              <Title>{intl.formatMessage({ id: "alarms" })}</Title>
              <BasicButton
                onClick={handleExport}
                icon={
                  <>
                    <DownloadIcon height={24} width={24} />
                  </>
                }
              >
                {intl.formatMessage({ id: "alarm_export" })}
              </BasicButton>
            </Flex>
            <Flex gap={24} align="center">
              <AlarmFilter
                locations={locations}
                devices={devices}
                users={users}
                setFilters={setFilters}
                setPageNumber={setPageNumber}
              />
              <div>
                <Body16RegularTertiary>
                  {intl.formatMessage({ id: "alarms_sortby" })}
                </Body16RegularTertiary>
                <SingleSelect
                  variant="borderless"
                  style={{ width: "auto" }}
                  popupMatchSelectWidth={200}
                  options={sortOptions}
                  onChange={(value) => setSortBy(value)}
                  value={sortBy}
                />
              </div>
            </Flex>
            <Table
              alarms={alarms}
              totalRows={totalRows}
              rowsPerPage={rowsPerPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setOpenUserModal={setOpenUserModal}
              setSelectedAlarm={setSelectedAlarm}
              unassignAlarm={unassignAlarm}
              updateAlarm={updateAlarm}
              getEscalationLogs={getEscalationLogs}
              escalationLogs={escalationLogs}
              updatedAlarms={updatedAlarms}
            />
          </Flex>
        </Card>
      </Flex>
      <UserSearchModal
        users={users}
        openUserModal={openUserModal}
        setOpenUserModal={setOpenUserModal}
        intl={intl}
        handleAssingTo={handleAssingTo}
      />
    </>
  );
};

export default Alarms;
