import { Tag, Badge } from "antd";
import { AlarmStatus, Severity } from "@types";
import { moment, lodash as _ } from "@datwyler/mfe-shared-components";

export const getStatusTag = (status, intl) => {
  switch (status) {
    case AlarmStatus.ACTIVE:
      return (
        <Tag bordered={false} color="green">
          {intl.formatMessage({ id: "alarm_active" })}
        </Tag>
      );
    case AlarmStatus.IN_PROGRESS:
      return (
        <Tag bordered={false} color="blue">
          {intl.formatMessage({ id: "alarm_in_progress" })}
        </Tag>
      );
    case AlarmStatus.ESCALATED:
      return (
        <Tag bordered={false} color="red">
          {intl.formatMessage({ id: "alarm_escalated" })}
        </Tag>
      );
    case AlarmStatus.RESOLVED:
      return (
        <Tag bordered={false} color="green">
          {intl.formatMessage({ id: "alarm_resolved" })}
        </Tag>
      );
    case AlarmStatus.DEACTIVATED:
      return (
        <Tag bordered={false} color="red">
          {intl.formatMessage({ id: "alarm_deactivated" })}
        </Tag>
      );
  }
};

export const getSeverityBadge = (severity, intl) => {
  switch (severity) {
    case Severity.CRITICAL:
      return (
        <Badge status="error" text={intl.formatMessage({ id: "critical" })} />
      );
    case Severity.INFORMATION:
      return (
        <Badge
          status="processing"
          text={intl.formatMessage({ id: "information" })}
        />
      );
    case Severity.WARNING:
      return (
        <Badge status="warning" text={intl.formatMessage({ id: "warning" })} />
      );
  }
};

export const getExcelData = (intl, alarmsData) => {
  const excelData = (alarmsData || [])?.map((alarm) => {
    const row = {};

    row[intl.formatMessage({ id: "alarm_id" })] = _.get(alarm, "id", "-");
    row[intl.formatMessage({ id: "alarm_device" })] = _.get(
      alarm,
      "device.name",
      "-"
    );
    row[intl.formatMessage({ id: "alarm_site" })] = _.get(
      alarm,
      "device.site.name",
      "-"
    );
    row[intl.formatMessage({ id: "alarm_attribute" })] = _.get(
      alarm,
      "values.attribute_name",
      "-"
    );
    row[intl.formatMessage({ id: "alarm_description" })] = _.get(
      alarm,
      "description",
      "-"
    );
    row[intl.formatMessage({ id: "alarm_level" })] = _.get(
      alarm,
      "severity",
      "-"
    );
    row[intl.formatMessage({ id: "alarm_date" })] = moment
      .unix(_.get(alarm, "time", "-"))
      .format("DD/MM/YY HH:mm");
    row[intl.formatMessage({ id: "alarm_status" })] = _.get(
      alarm,
      "status",
      "-"
    );
    row[intl.formatMessage({ id: "alarm_assigned_to" })] = `${_.get(
      alarm,
      "assignedTo.firstName",
      ""
    )} ${_.get(alarm, "assignedTo.lastName", "-")}`;

    row[intl.formatMessage({ id: "alarm_last_updated_by" })] = `${_.get(
      alarm,
      "updatedBy.firstName",
      ""
    )} ${_.get(alarm, "updatedBy.lastName", "-")}`;

    row[intl.formatMessage({ id: "alarm_last_updated_time" })] = moment
      .unix(_.get(alarm, "updatedOn", "-"))
      .format("DD/MM/YY HH:mm");

    return row;
  });
  return excelData;
};
