import { BasicBreadcrumbs, useIntl } from "@datwyler/mfe-shared-components";

const Breadcrumbs = ({
  items,
}: {
  items: {
    title: string;
  }[];
}) => {
  const intl = useIntl();

  return (
    <BasicBreadcrumbs
      items={[
        {
          title: intl.formatMessage({ id: "home" }),
          href: "/home",
        },
        ...items.map(({ title }) => ({
          title: intl.formatMessage({ id: title }),
        })),
      ]}
    />
  );
};

export default Breadcrumbs;
